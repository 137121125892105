// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo {
    height: 30px;
    padding-left: 0.5em;
    margin-right: 10px;
}

.help-button {
    margin-left: auto;
    margin-right: 1em;
}

.help-title {
     padding: 30px !important;
}

.help-content {
     padding: 30px !important;
}

`, "",{"version":3,"sources":["webpack://./src/webapp/css/main.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;IACjB,iBAAiB;AACrB;;AAEA;KACK,wBAAwB;AAC7B;;AAEA;KACK,wBAAwB;AAC7B","sourcesContent":[".logo {\n    height: 30px;\n    padding-left: 0.5em;\n    margin-right: 10px;\n}\n\n.help-button {\n    margin-left: auto;\n    margin-right: 1em;\n}\n\n.help-title {\n     padding: 30px !important;\n}\n\n.help-content {\n     padding: 30px !important;\n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
